import PersonIcon from '@material-ui/icons/Person';
import SettingsList from './SettingsList'
import SettingsEdit from './SettingsEdit'
import SettingsCreate from './SettingsCreate'

export default {
  create: SettingsCreate,
  edit: SettingsEdit,
  list: SettingsList,
  icon: PersonIcon,
};